//読み込み後アニメーション
window.addEventListener("load", function () {
	const LoadMask = document.getElementById('splash');

	setTimeout(function () {
		LoadMask.classList.add('move');
	}, 500);


	const LoaderImgs = document.querySelectorAll('.loader__img');

	LoaderImgs.forEach(function (LoaderImg) {
		setTimeout(function () {
			LoaderImg.classList.add('js-animated');
		}, 200);
	});


	const JsLoader = document.getElementById('js__loader');
	const body = document.querySelector('body');

	setTimeout(function () {
		JsLoader.classList.add('js-fadeOut');
		body.classList.remove('over-hidden');
	}, 2000);


});


$('#skinnyImg').on({
	'mouseenter': function () {
		$('.brand__img').attr('src', 'images/top_brand_skinny3.jpg');
	},
	'mouseleave': function () {
		$('.brand__img').attr('src', 'images/top_brand_skinny2.jpg');
	}
});

$('#baylisImg').on({
	'mouseenter': function () {
		$('.brand__img').attr('src', 'images/top_brand_baylis.jpg');
	},
	'mouseleave': function () {
		$('.brand__img').attr('src', 'images/top_brand_skinny2.jpg');
	}
});

$('#colabImg').on({
	'mouseenter': function () {
		$('.brand__img').attr('src', 'images/top_brand_colab.jpg');
	},
	'mouseleave': function () {
		$('.brand__img').attr('src', 'images/top_brand_skinny2.jpg');
	}
});

$('#eosImg').on({
	'mouseenter': function () {
		$('.brand__img').attr('src', 'images/top_brand_eos.jpg');
	},
	'mouseleave': function () {
		$('.brand__img').attr('src', 'images/top_brand_skinny2.jpg');
	}
});

$('#plusImg').on({
	'mouseenter': function () {
		$('.brand__img').attr('src', 'images/top_brand_plus6.jpg');
	},
	'mouseleave': function () {
		$('.brand__img').attr('src', 'images/top_brand_skinny2.jpg');
	}
});
